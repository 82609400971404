import React from 'react';
import { withRouter } from 'react-router';
import ReactModal from 'react-modal';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import MobileCategories from './Categories/MobileCategories';
import { ReactComponent as PhoneIcon } from '../../../svg/Phone.svg';
import { useLocation } from 'react-router';
import { theme } from '../../Theme';
import { StoreNavigator } from './Header';

const MobileMenuModal = styled(ReactModal)`
  position: absolute;
  right: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: ${theme.colors.white};
  border-top: 1px solid ${theme.colors.notWhite};
  padding: 1rem;
  padding-top: 0;
`;

const mobileMenuDefaultStyles = {
  overlay: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    right: '0px',
    bottom: '0px',
    backgroundColor: 'rgba(255, 255, 255, 1)',
    marginTop: '64px',
    zIndex: '99999',
  },
};

const basicTextStyles = `
  padding: 1rem 0;
  color: #111;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: ${theme.fontSizes[0]};
  line-height: 1.6;
  height: 57px;
`;

const StyledLink = styled(Link)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 5px;
  text-decoration: none;
  border-bottom: 1px solid ${theme.colors.notWhite};

  svg {
    width: 18px;
  }

  span {
    ${basicTextStyles};
    margin-left: 10px;
  }
`;

const MobileLinksContainer = styled('div')`
  ul {
    list-style: none;
    padding-left: 1rem;
  }

  li {
    ${basicTextStyles};
    border-bottom: 1px solid ${theme.colors.notWhite};
    color: #b6b6b6;
  }

  li > a {
    ${basicTextStyles};
    color: #b6b6b6;
  }

  /* ul > li:first-of-type {
    display: none;
  } */
`;

const goToPage = (e, history) => {
  e.preventDefault();
  const url = e.target.getAttribute('href');

  if (url.indexOf('http') !== -1) {
    window.open(url, '_newtab');
  } else {
    history.push({
      pathname: url,
      state: {
        type: 'page',
      },
    });
  }
};

ReactModal.setAppElement('#root');

const MobileMenu = ({
  data,
  isOpen,
  closeMenu,
  history,
  pageLinks,
  ...rest
}) => {
  const location = useLocation();
  if (!(data && data.categories)) return null;

  const locationPathName = location?.pathname;
  const categories = data.categories;

  const findPathInCategories = () => {
    let foundCategory = '';
    categories.forEach((cat) => {
      if (cat.primaryRoute.path === locationPathName) {
        foundCategory = cat.primaryRoute.path;
      }

      if (cat.hasSubcategories) {
        cat.subcategories.forEach((subcat) => {
          if (subcat.primaryRoute.path === locationPathName) {
            foundCategory = subcat.parent.primaryRoute.path;
          }
        });
      }
    });
    return foundCategory;
  };

  const categoryIndex = categories
    .map((e) => {
      return e.primaryRoute.path;
    })
    .indexOf(findPathInCategories());

  return (
    <MobileMenuModal
      defaultStyles={mobileMenuDefaultStyles}
      isOpen={isOpen}
      onRequestClose={closeMenu}
    >
      <MobileCategories
        categories={data.categories}
        closeMenu={closeMenu}
        categoryIndex={categoryIndex}
      />
      <StoreNavigator pageLinks={pageLinks} />
      <MobileLinksContainer>
        <ul>
          <li>
            <Link to="/om-oss" onClick={closeMenu}>
              OM OSS
            </Link>
          </li>
          <li>
            <Link to="/nyhetsbrev" onClick={closeMenu}>
              NYHETSBREV
            </Link>
          </li>
          <li>
            <Link to="/kontakta-oss" onClick={closeMenu}>
              {/* <PhoneIcon /> */}
              <span>Kontakt</span>
            </Link>
          </li>
        </ul>
      </MobileLinksContainer>
    </MobileMenuModal>
  );
};

export default withRouter(MobileMenu);
