import React, { useState } from 'react';
import { styled } from 'linaria/react';
import ColorCodes from './ColorCodes.json';
import { theme } from '../Theme';
import { cx } from 'linaria';

const BoxWrapper = styled('div')`
  cursor: pointer;
  position: relative;
  font-size: 14px;

  > div:nth-child(2) {
    outline: none;
    color: ${theme.colors.black};
    background-color: ${theme.colors.tablegrey};
    border-radius: 3px;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 6px;
    padding: 2px 5px;
    width: 100%;
    min-width: min-content;
    text-align: center;
  }

  &.active {
    > div {
      outline: 3px solid #fff;
      outline-offset: -4px;
      transform: scale(1.2, 1.2);
      font-size: 12px;
    }
  }
  > div:nth-child(2) {
    outline: none;
  }
`;
const Box = styled('div')`
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);
  background: linear-gradient(0.25turn, ${(props) => props.colors});
  --linewidth: 15px;
  &.big {
    height: 35px;
    width: 35px;
    border-radius: 50%;
    --linewidth: 35px;
  }

  &.medium {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin: 0 10px;

    --linewidth: 25px;
  }

  &.small {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    margin: 0 0.2rem;
    --linewidth: 15px;

    :hover {
      transform: scale(1.4, 1.4);
      transition: all 0.2s ease-in-out;
      z-index: 999;
    }
  }

  &:hover {
    transform: scale(1.2, 1.2);
    transition: all 0.2s ease-in-out;
  }

  // &::before {
  //   opacity: 0;
  //   position: absolute;
  //   top: -5px;
  //   left: 0;
  //   right: 0;
  //   margin: 0 auto;
  //   content: '';
  //   height: 2px;
  //   width: calc(var(--linewidth) - 4px);
  //   background: linear-gradient(0.25turn, ${(props) => props.colors});
  // }

  &:hover::before {
    opacity: 1;
  }
`;

const PopUpColor = styled('div')`
  position: absolute;
  z-index: 999;
  bottom: -2.3rem;
  margin-right: 7px;
  display: flex;
  justify-content: center;
  border: none !important;
`;

const ColorBox = ({
  group = null,
  articleNumber = null,
  clickClosure = null,
  boxsize = 'small',
  active,
  showLabel = false,
}) => {
  const [isShown, setIsShown] = useState(false);
  const colorList = () => {
    return group ? parseColorForGroup() : parseColorForArticle();
  };

  const parseColorForGroup = () => {
    let matchedColors = ColorCodes.filter((col) => {
      return col.group.toLowerCase().includes(group.toLowerCase());
    });
    if (matchedColors === undefined || matchedColors?.length === 0) {
      return null;
    }

    return matchedColors?.sort(sortColorsByLuma);
  };

  const parseColorForArticle = () => {
    // NEEDS IMPLEMENTATION
    return null;
  };

  const getLuma = (hex) => {
    var c = hex.substring(1); // strip #
    var rgb = parseInt(c, 16); // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff; // extract red
    var g = (rgb >> 8) & 0xff; // extract green
    var b = (rgb >> 0) & 0xff; // extract blue

    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709

    return luma;
  };

  const sortColorsByLuma = (a, b) => {
    if (getLuma(a.hexColor) > getLuma(b.hexColor)) {
      return -1;
    }
    if (getLuma(a.hexColor) < getLuma(b.hexColor)) {
      return 1;
    }
    return 0;
  };

  const colorObjectsToString = (list) => {
    if (!list) {
      return '#f3f3f3, #f3f3f3';
    }
    let hexColors = list?.map((color) => color.hexColor);
    if (hexColors.length === 1) {
      hexColors.push(hexColors[0]);
    }
    return hexColors?.join(', ');
  };

  return (
    <BoxWrapper
      className={cx(active ? 'active' : null)}
      colors={colorObjectsToString(colorList())}
    >
      <Box
        className={cx(boxsize, 'box')}
        colors={colorObjectsToString(colorList())}
        onClick={() => {
          if (clickClosure) {
            clickClosure();
          }
        }}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      />

      {isShown && showLabel && <PopUpColor>{group}</PopUpColor>}
    </BoxWrapper>
  );
};

export default ColorBox;
