import React from 'react';
import { styled } from 'linaria/react';
import { LargeProductCard } from '../CategoryPage/LargeProductCard';

const LargeProductCardListWrapper = styled('div')`
  .product-card {
    margin: 0;
  }
`;

export const LargeProductCardItem = ({ product, image }) => {
  return <LargeProductCard product={product?.value} image={image?.value} />;
};

export const LargeProductCardList = ({ children }) => {
  return <LargeProductCardListWrapper>{children}</LargeProductCardListWrapper>;
};
