import React, { useEffect, useContext } from 'react';
import { styled } from 'linaria/react';
import { useScript } from '../../hooks/useScript';
import { theme } from '../../Theme';
import ChannelContext from '@jetshop/core/components/ChannelContext';

const ProductRatingWrapper = styled('div')`
  margin-right: 10px;
  ${theme.below.lg} {
    margin-right: 5px;
  }

  .lipscore-compact-rating-wrapper,
  .lipscore-rating-small {
    display: flex;
    align-items: center;
    .lipscore-rating-star {
      fill: #feb846;
      height: 14px;
      width: 14px;
    }
    .lipscore-compact-rating-value,
    .lipscore-compact-rating-votes {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: -0.055em;
    }
    .lipscore-compact-rating-value {
      margin-left: 3px;
    }
    .lipscore-compact-rating-votes {
      opacity: 0.7;
    }
  }

  .lipscore-rating-slider {
    display: flex;
    align-items: center;
    .lipscore-rating-slider-stars-wrapper {
      .lipscore-rating-star-wrapper {
        .lipscore-rating-star {
          height: 20px;
          width: 20px;
        }
      }
    }
  }

  .lipscore-compact-rating-wrapper {
    display: flex;
    align-items: center;

    .lipscore-compact-rating-value {
      font-family: ${theme.fonts.primary};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: -0.055em;
      margin-left: 3px;
    }

    .lipscore-compact-rating-votes {
      opacity: 0.7;
      font-family: ${theme.fonts.primary};
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      display: flex;
      align-items: center;
      letter-spacing: -0.055em;
    }
  }

  .lipscore-rating-review-link {
    color: black;
    margin-left: 5px;
    display: none;
  }

  //********* END Styling for detail view *************

  //********* Styling for Card view *************

  .lipscore-rating-small {
    display: flex;
    margin-top: 1px;
    margin-bottom: 4px;
    .lipscore-rating-star {
      fill: #feb846;
      height: 14px;
      width: 14px;
    }
  }
`;

export const LipscoreProductSmallRating = ({
  product,
  onCard,
  handleClick,
}) => {
  const { selectedChannel } = useContext(ChannelContext);
  const apiKey = 'bea38eb1dfc86107b7f011c6';
  const language = selectedChannel?.country?.code?.toLowerCase() || 'en';
  const currency = selectedChannel?.currency?.name;
  const brand = product?.subName;
  const path = product?.primaryRoute?.path;
  const scriptUrl =
    language && `//static.lipscore.com/assets/${language}/lipscore-v1.js`;

  const [loaded, error] = useScript(scriptUrl);

  useEffect(() => {
    if (loaded && typeof window !== 'undefined' && window.lipscore) {
      if (!window.lipscore.isInitialized()) {
        window.lipscoreInit = () => {
          window.lipscore.init({ apiKey });
        };
        window.lipscore.apiKey = apiKey;
      }
      window.lipscore.initWidgets();
    }
  }, [loaded]);

  if (!loaded || error) return null;

  return (
    <ProductRatingWrapper>
      {onCard ? (
        <div
          className="lipscore-rating-small"
          ls-product-name={product?.name}
          ls-brand={brand}
          ls-product-id={product?.id}
          ls-product-url={selectedChannel?.url + path}
          ls-description={product?.shortDescription}
          ls-price={product?.price?.incVat}
          ls-price-currency={currency}
        />
      ) : (
        <div
          id="lipscore-rating"
          ls-product-name={product?.name}
          ls-brand={brand}
          ls-product-id={product?.id}
          ls-product-url={selectedChannel?.url + path}
          ls-description={product?.shortDescription}
          ls-price={product?.price?.incVat}
          ls-price-currency={currency}
          onClick={() => handleClick && handleClick('3')}
        />
      )}
    </ProductRatingWrapper>
  );
};
