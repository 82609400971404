import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { Link } from 'react-router-dom';

const MaxWidthWrapper = styled('div')`
  width: 100%;
`;

const BannerWrapper = styled('div')`
  width: 100%;
  height: fit-content;
  display: flex;
  padding: 4rem;
  justify-content: center;
  align-content: center;
  ${theme.below.lg} {
    padding: 2rem 2rem 4rem 2rem;
  }
`;

const Banner = styled('div')`
  width: 600px;

  ${theme.below.lg} {
    width: 70%;
  }
  ${theme.below.md} {
    width: 85%;
  }
  ${theme.below.sm} {
    width: 100%;
  }

  > * {
    margin-bottom: 2rem;
  }

  font-size: 16px;
  font-weight: 200;
  font-family: 'Lato';
  text-align: center;
  line-height: 24px;

  h2 {
    text-align: center;
    font-family: ${theme.fonts.primary};
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 130%; /* 52px */
  }

  &.fullWidth {
    width: 100%;
  }
`;

const CTA = styled(Link)`
  margin-top: 2rem;
  text-decoration: none;
  padding: 10px 20px 11px 20px;
  border-radius: 20px;

  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  opacity: 0.9;

  :hover {
    opacity: 1;
  }
`;

export const PLPTextBlock = ({
  title,
  text,
  background,
  foreground,
  fullWidth = null,
  buttonText,
  buttonTextColor,
  buttonBackgroundColor,
  buttonLink,
}) => {
  // Default fallback colors if background or foreground are undefined
  const backgroundColor = background?.value || '#ffffff'; // Default to white background
  const foregroundColor = foreground?.value || '#000000'; // Default to black text

  return (
    <MaxWidthWrapper>
      <BannerWrapper
        className="banner-wrapper"
        style={{ background: backgroundColor, color: foregroundColor }}
      >
        <Banner className={fullWidth?.value === true ? 'fullWidth' : ''}>
          {title?.value && <h2>{title?.value}</h2>}
          {text?.value && text?.value?.length > 11 && (
            <div
              dangerouslySetInnerHTML={{
                __html: text?.value,
              }}
            ></div>
          )}
          {buttonText?.value && (
            <CTA
              to={buttonLink?.value}
              style={{
                background: buttonBackgroundColor?.value || '#000000', // Default to black background
                color: buttonTextColor?.value || '#ffffff', // Default to white text
              }}
            >
              {buttonText?.value}
            </CTA>
          )}
        </Banner>
      </BannerWrapper>
    </MaxWidthWrapper>
  );
};
