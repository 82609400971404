import React, {
  useState,
  useCallback,
  useContext,
  memo,
  useMemo,
  useEffect,
} from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image/';
import ProductLink from '@jetshop/ui/ProductLink';
import CustomBadges from '../ui/CustomBadges';
import { Price } from '@jetshop/ui/Price';
import ColorBox from '../ui/ColorBox';
import { ProductDetailsWrapper } from '../ProductPage/styledComponents';
import { PriceComponent } from '../ProductPage/PriceComponent';
import { useLipscore } from '../../hooks/useLipscore';
import ChannelContext from '@jetshop/core/components/ChannelContext';

export const ProductWrapper = styled('div')`
  width: calc(100% / 4);
  ${theme.below.lg} {
    width: calc(100% / 3);
  }
  ${theme.below.md} {
    width: calc(100% / 3);
  }
  ${theme.below.sm} {
    width: 50%;
  }
`;

const ProductBlockLink = styled(ProductLink)`
  text-decoration: none;
  color: inherit;
  display: block;
  background-color: white;
  padding: 10px 0;
  position: relative;
  height: 100%;
  margin: 0 6px;

  [data-flight-image-container] {
    background: #f5f5f5;
  }
  [data-flight-image] {
    img {
      mix-blend-mode: multiply;
      transform: translate3d(0, 0, 0);
    }
  }

  .box {
    outline: 2px solid #f5f5f5;
    outline-offset: -3px;
  }
`;

const ImageWrapper = styled('div')`
  width: 100%;
  height: fit-content;
  position: relative;
`;

const Name = styled('p')`
  color: #000;
  font-family: ${theme.fonts.primary};
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.2px;
  text-align: left;
  width: 100%;
  min-width: 0;
  overflow: hidden;
  padding: 0;
  margin: 0;
`;

const ProductDetails = styled(ProductDetailsWrapper)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .more-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    justify-content: flex-end;
    [data-flight-price] {
      flex-direction: column;
      .new-price,
      .old-price,
      .price {
        margin: 0;
      }
    }
  }
  .subname {
    margin: 0;
    font-size: 12px;
    color: gray;
    font-weight: 300;
  }
  &.mastarpris {
    .new-price {
      line-height: 16px;
      color: #ef843c;
      font-weight: 700;
    }
    .old-price {
      opacity: 0.3;
      line-height: 18px;
      text-decoration: line-through;
    }
  }

  padding: 10px;
  align-items: flex-start;
  height: auto;
  justify-content: space-between;

  ${Price.Wrapper} {
    display: flex;
    flex-direction: row;
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    color: ${theme.colors.black};
  }

  ${Price.Old} {
    opacity: 0.3;
  }

  ${Price.New} {
    margin-right: 0.5rem;
  }

  ${theme.below.lg} {
    flex-direction: column;

    .more-info {
      justify-content: flex-start;

      [data-flight-price] {
        flex-direction: row;
        .new-price {
          margin-right: 5px;
        }
      }
    }
  }

  .lipscore-rating-small {
    display: flex;
    align-items: center;
    margin-top: 2px;

    .lipscore-rating-star {
      height: 14px;
      width: 14px;
    }

    .lipscore-compact-rating-value,
    .lipscore-compact-rating-votes {
      font-family: ${theme.fonts.primary};
      font-size: 12px;
      line-height: 14px;
      margin-left: 3px;
    }
  }

  .price-rating-wrapper {
    display: flex;
    ${theme.below.lg} {
      flex-direction: column-reverse;
      width: 100%;
    }
  }
`;

const ColorBoxesWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  min-height: 15px;
  align-items: center;
  background: #f5f5f5;
  width: 100%;
  padding: 4px 8px;
  height: 27px;
`;

const RemainingColors = styled('span')`
  font-size: 12px;
  color: #666;
  margin-left: 4px;
  font-weight: 300;
`;

const VariantsRatingsWrapper = styled('div')`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  /* padding-right: 10px; */
  min-height: 35px;
`;

export const ProductCard = memo(
  ({
    product,
    categoryPath,
    storeName,
    storeBadgeName,
    noLipscore,
    loadImageEagerly,
  }) => {
    const [imageIndex, setImageIndex] = useState(0);
    const { selectedChannel } = useContext(ChannelContext);
    const [showLipscore, setShowLipscore] = useState(false);

    useEffect(() => {
      const timer = setTimeout(() => {
        setShowLipscore(true);
      }, 100);

      return () => clearTimeout(timer);
    }, []);

    const storeBadge = useMemo(() => {
      if (!storeName || !storeBadgeName || storeName !== storeBadgeName)
        return null;

      return {
        name: 'store',
        location: 'TOP_LEFT',
        style: 'store-badge',
        text: storeName,
      };
    }, [storeName, storeBadgeName]);

    const handleMouseOver = useCallback(() => {
      if (product?.images?.length > 1) {
        setImageIndex((prevIndex) => (prevIndex === 1 ? 0 : 1));
      }
    }, [product]);

    if (!product) return null;

    const mastarPris = product?.customFields?.find(
      ({ key }) => key === 'Mastarpris'
    )?.boolValue;

    const colorVariants = product?.variants?.options?.find((option) =>
      option?.name?.toLowerCase()?.includes('färg')
    )?.values;

    const path = product?.primaryRoute?.path;
    const currency = selectedChannel?.currency?.name;

    const moddedProduct = {
      ...product,
      primaryRoute: {
        ...product?.primaryRoute,
        path: product?.primaryRoute?.path?.replace(
          'https://www.mobelmastarna.se',
          ''
        ),
      },
    };

    const maxVisibleColors = 5;
    const remainingColors = colorVariants?.length - maxVisibleColors;

    return (
      <ProductBlockLink
        className="product-card"
        product={moddedProduct}
        categoryPath={categoryPath}
        onMouseEnter={handleMouseOver}
        onMouseLeave={handleMouseOver}
      >
        <ImageWrapper>
          <Image
            sizes={[1 / 4, 1 / 3, 1 / 2, 1 / 2]}
            alt={product?.name}
            src={
              product?.images?.length > 1
                ? product?.images[imageIndex]?.url
                : product?.images[0]?.url
            }
            critical={loadImageEagerly}
          />
          {(product?.badges || storeBadge) && (
            <CustomBadges
              badges={
                storeBadge
                  ? [...(product?.badges || []), storeBadge].filter(Boolean)
                  : product?.badges || []
              }
            />
          )}
        </ImageWrapper>
        <VariantsRatingsWrapper>
          <ColorBoxesWrapper>
            {colorVariants?.slice(0, maxVisibleColors).map((color, index) => (
              <ColorBox group={color} key={color + index} />
            ))}
            {remainingColors > 0 && (
              <RemainingColors>+{remainingColors}</RemainingColors>
            )}
          </ColorBoxesWrapper>
        </VariantsRatingsWrapper>
        <ProductDetails className={mastarPris ? 'mastarpris' : null}>
          <div style={{ width: '100%' }}>
            <Name>{product?.name}</Name>
            {product?.subName && product?.subName !== storeName && (
              <p className="subname">{product?.subName}</p>
            )}

            {/* <LipscoreProductSmallRating product={product} onCard /> */}
            <div className="price-rating-wrapper">
              {showLipscore &&
                product &&
                !noLipscore &&
                selectedChannel?.url &&
                path && (
                  <div
                    className="lipscore-rating-small"
                    data-ls-product-name={product?.name}
                    data-ls-brand={product?.subName}
                    data-ls-product-id={product?.id}
                    data-ls-product-url={selectedChannel?.url + path}
                    data-ls-description={product?.shortDescription}
                    data-ls-price={product?.price?.incVat}
                    data-ls-price-currency={currency}
                  />
                )}
              <div className="more-info">
                <PriceComponent product={product} onCard={true} />
              </div>
            </div>
          </div>
        </ProductDetails>
      </ProductBlockLink>
    );
  }
);

export const SearchProductCard = (props) => (
  <ProductWrapper>
    <ProductCard {...props} />
  </ProductWrapper>
);
