import { StructuredData } from '@jetshop/core/components/StructuredData';
import { styled } from 'linaria/react';
import React, { createContext, useContext, useState, useEffect } from 'react';
import { theme } from '../Theme';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import { ReactComponent as Up } from '../../svg/CarretUp.svg';
import { ReactComponent as Down } from '../../svg/CarretDown.svg';

const FaqWrapper = styled('div')`
  width: 100%;
  max-width: 60rem;
  margin: auto;

  .inner {
    columns: 2;
    column-gap: 40px;
  }

  h3 {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 48px */
  }

  a {
    text-decoration: underline;
    color: #000;
  }

  .FAQitem {
    break-inside: avoid;
    page-break-inside: avoid;

    h4 {
      color: #000;
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%; /* 20.8px */
      padding: 0;
      margin: 0;
    }
    p {
      color: #000;
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 20.8px */
      margin: 0;
    }
    margin-bottom: 20px;
  }
`;

const AccordionFieldWrapper = styled('div')`
  width: 100%;
  padding: 20px;
  max-width: 60rem;
  margin: auto;

  .top {
    margin-bottom: 30px;
    h3 {
      color: #000;
      font-family: ${theme.fonts.primary};
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%; /* 40px */
      margin: 0;
      margin-bottom: 10px;
    }

    p {
      color: #000;
      font-family: ${theme.fonts.primary};
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 20.8px */
      margin: 0;
    }
  }

  h4 {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 20.8px */
    padding: 0;
    margin: 0;
  }
  p {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%; /* 20.8px */
    margin: 0;
  }
  a {
    text-decoration: underline;
    color: #000;
  }
  .accordion-content {
    margin: 10px 0 20px 0;
  }
  section {
    border-top: 1px solid #e4e4e4;
  }
  section:last-of-type {
    border-bottom: 1px solid #e4e4e4;
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1.25rem;
  display: flex;
`;

const AccordionTitle = styled('div')`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0 !important;
  align-items: center;
`;

export const AccordionContext = createContext();

export const FAQItem = ({ answer, question }) => {
  const accordionContext = useContext(AccordionContext);

  const isMobile = accordionContext !== undefined;
  const isOpen = isMobile
    ? accordionContext.openIndexes.includes(accordionContext.index)
    : true;

  const AccordionIndicator = ({ isOpen }) => (
    <IndicatorIcon>{isOpen ? <Up /> : <Down />}</IndicatorIcon>
  );

  return (
    <>
      <Above breakpoint="lg">
        <div className="FAQitem">
          <h4>{question?.value}</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: answer?.value,
            }}
          />
        </div>
      </Above>

      <Below breakpoint="lg">
        {isMobile && (
          <section>
            <AccordionTitle
              onClick={() =>
                accordionContext.handleClick(accordionContext.index)
              }
            >
              <h4>{question?.value}</h4>
              <AccordionIndicator isOpen={isOpen} />
            </AccordionTitle>

            {accordionContext.AccordionContent && (
              <accordionContext.AccordionContent isOpen={isOpen}>
                <div className="accordion-content">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: answer?.value,
                    }}
                  />
                </div>
              </accordionContext.AccordionContent>
            )}
          </section>
        )}
      </Below>
    </>
  );
};

const AccordionContent = ({ isOpen, children }) => {
  return <div style={{ display: isOpen ? 'block' : 'none' }}>{children}</div>;
};

const Accordion = ({ children, single = false, initialOpenIndexes = [] }) => {
  const [openIndexes, setOpenIndexes] = useState(initialOpenIndexes);

  const handleClick = (index) => {
    if (single) {
      setOpenIndexes((prev) => (prev.includes(index) ? [] : [index]));
    } else {
      setOpenIndexes((prev) =>
        prev.includes(index)
          ? prev.filter((i) => i !== index)
          : [...prev, index]
      );
    }
  };

  return children({
    openIndexes,
    handleClick,
    AccordionContent,
  });
};

// Create a context to collect all FAQ data
export const FAQStructuredDataContext = createContext({
  addFAQData: () => {},
  removeFAQData: () => {},
});

// Create a provider component to wrap your content area
export const FAQStructuredDataProvider = ({ children }) => {
  const [faqDataItems, setFaqDataItems] = useState([]);

  const addFAQData = (items) => {
    // Generate unique IDs for each set of FAQ items
    const itemsWithIds = items.map((item) => ({
      ...item,
      id: Math.random().toString(36).substring(2, 9),
    }));

    setFaqDataItems((prev) => [...prev, ...itemsWithIds]);
    return itemsWithIds.map((item) => item.id); // Return IDs for later removal
  };

  const removeFAQData = (ids) => {
    setFaqDataItems((prev) => prev.filter((item) => !ids.includes(item.id)));
  };

  return (
    <FAQStructuredDataContext.Provider value={{ addFAQData, removeFAQData }}>
      <StructuredData
        json={[
          {
            '@context': 'https://schema.org',
            '@type': 'FAQPage',
            mainEntity: faqDataItems,
          },
        ]}
      />
      {children}
    </FAQStructuredDataContext.Provider>
  );
};

export const FAQ = ({ children, title, text }) => {
  const { addFAQData, removeFAQData } = useContext(FAQStructuredDataContext);
  const [faqItemIds, setFaqItemIds] = useState([]);

  // Process FAQ data when component mounts or children change
  useEffect(() => {
    const faqData = [];

    if (children) {
      children.forEach((child) => {
        if (child?.props?.question?.value && child?.props?.answer?.value) {
          faqData.push({
            '@type': 'Question',
            name: child.props.question.value,
            acceptedAnswer: {
              '@type': 'Answer',
              text: child.props.answer.value,
            },
          });
        }
      });
    }

    // Add data to context and store IDs
    const ids = addFAQData(faqData);
    setFaqItemIds(ids);

    // Cleanup when component unmounts
    return () => {
      removeFAQData(ids);
    };
  }, [children, addFAQData, removeFAQData]);

  return (
    <>
      <Above breakpoint="lg">
        <FaqWrapper>
          {title?.value && <h3>{title?.value}</h3>}
          <div className="inner">
            {text?.value && (
              <div className="FAQitem">
                <div
                  dangerouslySetInnerHTML={{
                    __html: text?.value,
                  }}
                />
              </div>
            )}
            {children}
          </div>
        </FaqWrapper>
      </Above>

      <Below breakpoint="lg">
        <AccordionFieldWrapper>
          <div className="top">
            {title?.value && <h3>{title?.value}</h3>}
            {text?.value && (
              <div
                dangerouslySetInnerHTML={{
                  __html: text?.value,
                }}
              />
            )}
          </div>

          <Accordion single>
            {({ openIndexes, handleClick, AccordionContent }) => (
              <>
                {children?.map((child, index) => (
                  <AccordionContext.Provider
                    key={index}
                    value={{
                      index,
                      openIndexes: openIndexes ?? 0,
                      handleClick: handleClick,
                      AccordionContent,
                    }}
                  >
                    {child}
                  </AccordionContext.Provider>
                ))}
              </>
            )}
          </Accordion>
        </AccordionFieldWrapper>
      </Below>
    </>
  );
};
