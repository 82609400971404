import { useEffect } from 'react';

export const useLipscore = () => {
  useEffect(() => {
    if (typeof window === 'undefined') return;

    let initAttempts = 0;
    const maxAttempts = 5;

    const initLipscore = () => {
      if (window.lipscore) {
        window.lipscore.initWidgets();
        return true;
      }
      return false;
    };

    const tryInit = () => {
      if (initAttempts >= maxAttempts) return;

      if (!initLipscore()) {
        // If not initialized yet, set up initialization
        if (!window.lipscoreInit) {
          window.lipscoreInit = function () {
            window.lipscore.init({
              apiKey: 'bea38eb1dfc86107b7f011c6',
            });
          };

          const script = document.createElement('script');
          script.async = 1;
          script.src = '//static.lipscore.com/assets/sv/lipscore-v1.js';
          document.getElementsByTagName('head')[0].appendChild(script);
        }
      }
      initAttempts++;
    };

    // Try immediate initialization
    tryInit();

    // Set up periodic checks
    const interval = setInterval(() => {
      if (tryInit() || initAttempts >= maxAttempts) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);
};
