import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const MaxWidthWrapper = styled('div')`
  width: 100%;
`;

const BannerWrapper = styled('div')`
  width: 100%;
  height: fit-content;
  display: flex;
  padding: 4rem;
  justify-content: center;
  align-content: center;

  ${theme.below.md} {
    padding: 6rem 2rem 6rem 2rem;
  }
`;

const Banner = styled('div')`
  width: 600px;

  ${theme.below.lg} {
    width: 70%;
  }
  ${theme.below.md} {
    width: 85%;
  }
  ${theme.below.sm} {
    width: 100%;
  }

  > * {
    margin-bottom: 1.5rem;
  }

  font-size: 16px;
  font-weight: 200;
  font-family: 'Lato';
  text-align: center;
  line-height: 24px;

  h1 {
    text-align: center;
    font-size: 28px;
    font-weight: 400;
    font-family: 'Lato';
    line-height: 36px;
    margin-bottom: 1.5rem;
  }

  &.fullWidth {
    width: 100%;
  }
`;

const ColorBanner = ({
  children,
  backgroundColor = '#E8E4E1', // Default background color
  foregroundColor = 'black', // Default foreground color
  fullWidth = null,
  ...rest
}) => {
  return (
    <MaxWidthWrapper>
      <BannerWrapper
        style={{
          background: backgroundColor || '#E8E4E1', // Fallback to default if undefined
          color: foregroundColor || 'black', // Fallback to default if undefined
        }}
      >
        <Banner className={fullWidth ? 'fullWidth' : ''}>{children}</Banner>
      </BannerWrapper>
    </MaxWidthWrapper>
  );
};

export default ColorBanner;
