import React from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import Slider from 'react-slick';
import { ReactComponent as Arrow } from '../../svg/Arrow.svg';
import { theme } from '../Theme';

const ImageGalleryWrapper = styled('div')`
  max-width: 60rem;
  margin: auto;

  &.fullwidth {
    max-width: 100%;
  }
`;

const ArrowWrapper = styled('div')`
  height: 30px;
  width: 30px;
  border-radius: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
  cursor: pointer;
  position: absolute;
  bottom: 20px;
  right: 20px;
  transform: translateY(-50%);
  z-index: 1;
  background: white;

  &.next {
    transform: rotate(270deg);
  }

  &.prev {
    transform: rotate(90deg);
    right: 56px;
  }
`;

const CustomArrow = styled(Arrow)`
  height: 14px;
  width: 14px;
`;

function PrevArrow(props) {
  const { className, onClick } = props;
  return (
    <ArrowWrapper className={className + ' prev'} onClick={onClick}>
      <CustomArrow />
    </ArrowWrapper>
  );
}

function NextArrow(props) {
  const { className, onClick } = props;
  return (
    <ArrowWrapper className={className + ' next'} onClick={onClick}>
      <CustomArrow />
    </ArrowWrapper>
  );
}

export const ImageGalleryItem = ({ imageUrl, aspectRatio }) => {
  return (
    <div>
      <Image src={imageUrl?.value} aspect={aspectRatio?.value} cover={true} />
    </div>
  );
};

export const ImageGallery = ({ children, fullWidth }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1.2,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    draggable: true,
    responsive: [
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <ImageGalleryWrapper className={fullWidth?.value && 'fullwidth'}>
      <Slider {...settings}>{children}</Slider>
    </ImageGalleryWrapper>
  );
};
