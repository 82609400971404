import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { ReactComponent as StoreIcon } from '../../svg/StoreIcon.svg';

const CustomBadgesContainerWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  position: absolute;
  justify-content: space-between;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 20px;
  @media (max-width: 500px) {
    padding: 10px;
  }
`;

const CustomBadgesContainer = styled('div')`
  display: flex;
  flex-direction: column;
  &.top {
    justify-content: flex-start;
    > * {
      margin-bottom: 5px;
    }
  }

  &.bottom {
    justify-content: flex-end;
    > * {
      margin-top: 5px;
    }
  }
`;

const CustomBadgeWrapper = styled('div')`
  height: 20px;
  width: auto;
  padding: 0 0.6rem;
  color: white !important;
  line-height: 10px;
  text-transform: uppercase;
  font-size: 10px;
  font-family: Lato;
  font-weight: 800;
  letter-spacing: 0.1em;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &.left {
    align-self: flex-start;
  }
  &.right {
    align-self: flex-end;
  }
  @media (max-width: 500px) {
    font-size: 8px;
    line-height: 8px;
  }

  p {
    width: auto;
  }

  /**** START - CSS styles from admin ****/
  /*default*/
  background: black;
  color: white;

  &.news-badge {
    background: #ef843c;
    color: white;
    border-radius: 20px;
  }

  &.info-badge {
    background: #f2f2f2 !important;
    color: black !important;
    border-radius: 20px;
  }

  &.campaign {
    background: #dd2e2e;
    color: white;
    border-radius: 20px;
  }

  &.store-badge {
    background: #911830;
    color: white;
    border-radius: 20px;
    /* position: absolute; */
    svg {
      margin-right: 6px;
      height: 12px;
    }
  }
  &.default {
    border-radius: 20px;
  }
  /*Add more styles*/
  /**** END - CSS styles from admin ****/

  &.image {
    background: transparent;
    height: auto;
    width: auto;
    padding: 0;
  }
`;

export const CustomBadges = ({
  badges,
  horizontalInverted = false,
  fullHeight,
}) => {
  const alignment = (badge) => {
    switch (badge?.location) {
      case 'TOP_LEFT':
        return horizontalInverted ? ' top right' : ' top left';
      case 'TOP_RIGHT':
        return horizontalInverted ? ' top left' : ' top right';
      case 'BOTTOM_LEFT':
        return horizontalInverted ? ' bottom right' : ' top left';
      case 'BOTTOM_RIGHT':
        return horizontalInverted ? ' bottom left' : ' bottom right';
      default:
        break;
    }
    return 'top left';
  };

  const topBadges = badges?.filter((x) => x?.location?.includes('TOP'));
  const bottomBadges = badges?.filter((x) => x?.location?.includes('BOTTOM'));

  function sortByKey(array, key) {
    return array?.sort(function (a, b) {
      var x = a[key];
      var y = b[key];
      return x < y ? -1 : x > y ? 1 : 0;
    });
  }

  const sortedBottomBadges = sortByKey(bottomBadges, 'style');
  const sortedTopBadges = sortByKey(topBadges, 'style');

  return (
    <CustomBadgesContainerWrapper
      className={cx('custom-badges', fullHeight && 'full-height')}
    >
      <CustomBadgesContainer className={'top'}>
        {sortedTopBadges?.map((badge, index) => (
          <CustomBadgeWrapper
            key={index + '-' + badge.name}
            className={
              alignment(badge) +
              ' ' +
              badge.style +
              ' ' +
              ((badge?.style?.length === 0 ? badge?.url?.length > 0 : null)
                ? 'image'
                : '')
            }
          >
            {badge?.style === 'store-badge' && <StoreIcon />}
            {badge?.style?.length === 0 && badge?.url?.length > 0 ? (
              <img alt="" src={badge?.url}></img>
            ) : (
              <p>{badge.text.length > 0 ? badge.text : badge.name}</p>
            )}
          </CustomBadgeWrapper>
        ))}
      </CustomBadgesContainer>
      <CustomBadgesContainer className={'bottom'}>
        {sortedBottomBadges?.map((badge, index) => (
          <CustomBadgeWrapper
            key={index + '-' + badge.name}
            className={
              alignment(badge) +
              ' ' +
              badge.style +
              ' ' +
              ((badge?.style?.length === 0 ? badge?.url?.length > 0 : null)
                ? 'image'
                : '')
            }
          >
            {badge?.style === 'store-badge' && <StoreIcon />}
            {badge?.style?.length === 0 && badge?.url?.length > 0 ? (
              <img alt="" src={badge?.url}></img>
            ) : (
              <p>{badge.text.length > 0 ? badge.text : badge.name}</p>
            )}
          </CustomBadgeWrapper>
        ))}
      </CustomBadgesContainer>
    </CustomBadgesContainerWrapper>
  );
};
export default CustomBadges;
