import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

const ContactWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;

  ${theme.below.lg} {
    justify-content: flex-start;
    padding: 40px 20px;
  }

  .inner {
    p,
    a,
    h3 {
      color: #000;
      font-family: ${theme.fonts.primary};
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 19.2px */
      margin: 0;
    }
    h4 {
      color: #000;
      font-family: ${theme.fonts.primary};
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%;
      margin: 0;
    }
  }

  .image {
    margin-right: 20px;
  }
`;

export const Contact = ({
  title,
  profileImage,
  profileName,
  profileTitle,
  profileMail,
}) => {
  return (
    <ContactWrapper>
      <div className="image">
        <img src={profileImage?.value} alt={profileName?.value} />
      </div>
      <div className="inner">
        <h3>{title?.value}</h3>
        <h4>{profileName?.value}</h4>
        <p>{profileTitle?.value}</p>
        <a href={`mailto:${profileMail?.value}`}>{profileMail?.value}</a>
      </div>
    </ContactWrapper>
  );
};
