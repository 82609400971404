import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';

const ImagePushWrapper = styled('div')`
  width: 100%;
  max-width: 60rem;
  margin: auto;
  display: flex;

  ${theme.below.lg} {
    flex-direction: column;
  }

  .image-item {
    width: 100%;
    position: relative;
  }

  .content {
    width: 100%;
    max-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 10%;
    left: 50%;
    transform: translate(-50%);
    text-align: center;

    h3,
    h4,
    a {
      margin: 0;
    }

    h3 {
      text-align: center;
      font-family: ${theme.fonts.primary};
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-bottom: 8px;
    }

    h4 {
      font-family: ${theme.fonts.primary};
      font-size: 18px;
      line-height: 160%;
      font-weight: 600;
      text-align: center;
      margin: 0;
    }

    .text {
      margin-bottom: 6px;
    }
  }
`;

const CTA = styled(Link)`
  border-radius: 20px;
  font-family: ${theme.fonts.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  height: 30px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: fit-content;

  :hover {
    opacity: 0.9;
  }
`;

export const ImagePushImageItem = ({
  desktopImage,
  desktopAspect,
  mobileImage,
  mobileAspect,
  textColor,
  title,
  text,
  buttonText,
  buttonBackgroundColor,
  buttonTextColor,
  buttonLink,
}) => {
  return (
    <div className="image-item">
      <Above breakpoint="lg">
        {(matches) => (
          <>
            <Image
              cover={true}
              src={matches ? desktopImage?.value : mobileImage?.value}
              aspect={matches ? desktopAspect?.value : mobileAspect?.value}
            />
            <div className="content" style={{ color: textColor?.value }}>
              <h3>{title?.value}</h3>
              <div
                className="text"
                dangerouslySetInnerHTML={{
                  __html: text?.value,
                }}
              />
              <CTA
                to={buttonLink?.value}
                style={{
                  color: buttonTextColor?.value,
                  background: buttonBackgroundColor?.value,
                }}
              >
                {buttonText?.value}
              </CTA>
            </div>
          </>
        )}
      </Above>
    </div>
  );
};

export const ImagePush = ({ children }) => {
  return <ImagePushWrapper>{children}</ImagePushWrapper>;
};
