import { Hero } from './Hero';
import { BoxRow } from './BoxRow';
import { CampaignRow, CampaignRowProduct } from './CampaignRow';
import { ImageBoxItem, TextBoxItem, ProductBoxItem } from './BoxItems';
import { Spacer } from './Spacer';
import { ProductRow, ProductRowItem } from './ProductRow';
import { TextPush } from './TextPush';
import { Title } from './Title';
import { ContentRow, ContentRowItem } from './ContentRow';
import { VideoPopup } from '../Content/PreviewAndVideoPopup';
import { CareProduct } from './CareProduct';
import { ImageLinksRow, ImageLinksRowItem } from './ImageLinksRow';
import { ImageGallery, ImageGalleryItem } from './ImageGallery';
import {
  TextSection,
  TextSectionIngressItem,
  TextSectionItem,
} from './TextSection';
import {
  LargeProductCardList,
  LargeProductCardItem,
} from './LargeProductCardList';
import { PLPTextBlock } from './PLPTextBlock';
import { FAQ, FAQItem } from './FAQ';
import { ImagePush, ImagePushImageItem } from './ImagePush';
import {
  ImageTextPush,
  ImageTextPushImageItem,
  ImageTextPushTextItem,
} from './ImageTextPush';
import { Contact } from './Contact';

export const contentEditorComponents = {
  HERO: Hero,
  TEXTBOX: TextBoxItem,
  IMAGEBOX: ImageBoxItem,
  PRODUCTBOX: ProductBoxItem,
  SPACER: Spacer,
  BOXROW: BoxRow,
  CAMPAIGNROW: CampaignRow,
  CAMPAIGNROWPRODUCT: CampaignRowProduct,
  PRODUCTROW: ProductRow,
  PRODUCTROWITEM: ProductRowItem,
  TEXTPUSH: TextPush,
  TITLE: Title,
  CONTENTROW: ContentRow,
  CONTENTROWITEM: ContentRowItem,
  VIDEOPLAYER: VideoPopup,
  CAREPRODUCT: CareProduct,
  IMAGELINKSROW: ImageLinksRow,
  IMAGELINKSROWITEM: ImageLinksRowItem,
  CATEGORYIMAGELINKSROW: ImageLinksRow,
  CATEGORYIMAGELINKSROWITEM: ImageLinksRowItem,
  IMAGEGALLERY: ImageGallery,
  IMAGEGALLERYITEM: ImageGalleryItem,
  TEXTSECTION: TextSection,
  TEXTSECTIONITEM: TextSectionItem,
  TEXTSECTIONINGRESSITEM: TextSectionIngressItem,
  FAQ: FAQ,
  FAQITEM: FAQItem,
  IMAGETEXTPUSH: ImageTextPush,
  IMAGETEXTPUSHIMAGEITEM: ImageTextPushImageItem,
  IMAGETEXTPUSHTEXTITEM: ImageTextPushTextItem,
  IMAGEPUSH: ImagePush,
  IMAGEPUSHIMAGEITEM: ImagePushImageItem,
  CONTACT: Contact,
};

export const categoryPageComponents = {
  PLP_LARGEPRODUCTCARDLIST: LargeProductCardList,
  LARGEPRODUCTCARDITEM: LargeProductCardItem,
  PLP_TEXTBLOCK: PLPTextBlock,
};
