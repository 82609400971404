import React, { useState, useContext, useMemo, memo } from 'react';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image/';
import BaseProductGrid from '@jetshop/ui/ProductList/ProductGrid';
import ProductLink from '@jetshop/ui/ProductLink';
import { Price } from '@jetshop/ui/Price';
import { ProductDetailsWrapper } from '../ProductPage/styledComponents';
import ColorBox from '../ui/ColorBox';
import CustomBadges from '../ui/CustomBadges';
import { ProductCard } from './ProductCard';

import { theme } from '../Theme';
import { StoreContext } from '../StoreHandler/StoreContext';

const ProductDetails = styled(ProductDetailsWrapper)`
  padding: 1em;
  align-items: center;
  p {
    margin-top: 0;
    margin-bottom: 0;
  }
  ${Price.Wrapper} {
    display: flex;
    flex-direction: row;
    margin: 0;
    font-weight: 400;
    font-size: 12px;
    color: ${theme.colors.black};
  }

  ${Price.Old} {
    opacity: 0.3;
  }

  ${Price.New} {
    margin-right: 0.5rem;
  }
`;

const ImageWrapper = styled('div')`
  width: 100%;
  height: fit-content;
  border: 1px solid #e8e8e8;
  padding: 2rem;
  padding-top: 3rem;
  position: relative;
`;

const ProductBlockLink = styled(ProductLink)`
  text-decoration: none;
  color: inherit;
  display: block;
  margin: 0;
  background-color: white;
  padding: 0;
  position: relative;
  height: 100%;
`;

const Name = styled('p')`
  font-weight: 300;
  font-size: 12px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0;
`;

const ColorBoxesWrapper = styled('div')`
  margin: 0.5rem 0;
  display: flex;
  justify-content: flex-start;
  min-height: 15px;
`;

const ProductGrid = styled(BaseProductGrid)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-row-gap: 15px;

  ${theme.below.lg} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ProductWrapper = styled('div')``;

export function isAboveFold(index) {
  return index < 4;
}

export const ProductComponent = ({
  product,
  categoryPath,
  loadImageEagerly,
}) => {
  const [image, setImage] = useState(product.images[0]?.url);

  const handleMouseOver = () => {
    if (product.images.length > 1) {
      if (image === product.images[1].url) {
        setImage(product.images[0].url);
      } else {
        setImage(product.images[1].url);
      }
    }
  };

  const colorVariants = product.variants?.options?.find((option) =>
    option.name.toLowerCase().includes('färg')
  )?.values;
  return (
    <ProductWrapper data-testid="product">
      <ProductBlockLink
        product={product}
        categoryPath={categoryPath}
        onMouseEnter={() => handleMouseOver()}
        onMouseLeave={() => handleMouseOver()}
      >
        <ImageWrapper>
          <Image
            sizes={[1 / 4, 1 / 3, 1 / 2, 1 / 2]}
            alt={image && image}
            src={image && image}
            critical={loadImageEagerly}
          />
          {product?.badges && <CustomBadges badges={product.badges} />}
        </ImageWrapper>
        <ProductDetails>
          <Name>{product.name}</Name>
          <ColorBoxesWrapper>
            {colorVariants?.map((color, index) => (
              <ColorBox group={color} key={color + index} />
            ))}
          </ColorBoxesWrapper>
          {/* <Description>{product.subName}23</Description> */}
          <Price previousPrice={product.previousPrice} price={product.price} />
        </ProductDetails>

        {/* {isPackage && <Badge>{t('Package')}</Badge>} */}
      </ProductBlockLink>
    </ProductWrapper>
  );
};

export const StyledProductGrid = memo(
  ({
    loading,
    products,
    listName,
    categoryPath,
    ProductComponent = ProductCard,
  }) => {
    const { selectedStore } = useContext(StoreContext);

    // Add index to each product
    const productsWithIndex = products.map((product, index) => ({
      ...product,
      index,
    }));

    const wrappedProductComponent = useMemo(
      () =>
        ({ product, ...props }) => {
          return (
            <ProductComponent
              {...props}
              product={product}
              storeName={selectedStore?.name}
              storeBadgeName={product?.subName}
              loadImageEagerly={isAboveFold(product.index)}
            />
          );
        },
      [selectedStore?.name]
    );

    return (
      <ProductGrid
        storeName={selectedStore?.name}
        listName={listName}
        products={productsWithIndex}
        loading={loading}
        categoryPath={categoryPath}
        ProductComponent={wrappedProductComponent}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.products === nextProps.products &&
      prevProps.loading === nextProps.loading
    );
  }
);

export default StyledProductGrid;
