import React from 'react';
import Image from '@jetshop/ui/Image/Image';
import { Above } from '@jetshop/ui/Breakpoints';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';

const ImagePushWrapper = styled('div')`
  width: 100%;
  max-width: 60rem;
  margin: auto;
  display: flex;

  ${theme.below.lg} {
    flex-direction: column;
  }

  .image-item {
    width: 100%;
  }
  .text-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 50px;

    ${theme.below.lg} {
      padding: 30px 20px;
    }

    h3 {
      color: #000;
      font-family: ${theme.fonts.primary};
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 160%; /* 35.2px */
      margin: 0;
      margin-bottom: 10px;

      ${theme.below.lg} {
        font-size: 20px;
      }
    }

    p {
      color: #000;
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 160%; /* 22.4px */
      margin: 0;

      ${theme.below.lg} {
        font-size: 13px;
      }
    }
  }
`;

const CTA = styled(Link)`
  border-radius: 20px;
  font-family: ${theme.fonts.primary};
  font-size: 12px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  height: 34px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  width: fit-content;
  margin-top: 20px;

  :hover {
    opacity: 0.9;
  }
`;

export const ImageTextPushTextItem = ({
  backgroundColor,
  textColor,
  title,
  text,
  buttonText,
  buttonBackgroundColor,
  buttonTextColor,
  buttonLink,
}) => {
  return (
    <div className="text-item" style={{ background: backgroundColor?.value }}>
      <h3 style={{ color: textColor?.value }}>{title?.value}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: text?.value,
        }}
      />
      <CTA
        to={buttonLink?.value}
        style={{
          color: buttonTextColor?.value,
          background: buttonBackgroundColor?.value,
        }}
      >
        {buttonText?.value}
      </CTA>
    </div>
  );
};

export const ImageTextPushImageItem = ({
  desktopImage,
  desktopAspect,
  mobileImage,
  mobileAspect,
}) => {
  return (
    <div className="image-item">
      <Above breakpoint="lg">
        {(matches) => (
          <Image
            cover={true}
            src={matches ? desktopImage?.value : mobileImage?.value}
            aspect={matches ? desktopAspect?.value : mobileAspect?.value}
          />
        )}
      </Above>
    </div>
  );
};

export const ImageTextPush = ({ children }) => {
  return <ImagePushWrapper>{children}</ImagePushWrapper>;
};
