import React from 'react';
import { Price } from '../Price';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { useQuery } from '@apollo/react-hooks';
import PriceHistoryQuery from './PriceHistoryQuery.gql';

const PriceWrapper = styled('div')`
  .price {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 114%;
  }
  .new-price {
    color: ${theme.colors.red};
    font-family: ${theme.fonts.primary};
    font-size: 40px;
    font-style: normal;
    font-weight: 900;
    line-height: 114%;
  }
  .old-price {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 114%;
    text-decoration: none;
  }
  &.mastarpris {
    .price {
      color: ${theme.colors.newOrage};
      font-family: ${theme.fonts.primary};
      font-size: 40px;
      font-style: normal;
      font-weight: 900;
      line-height: 114%;
    }
  }
  .mastarpris-wrapper {
    position: relative;
    width: fit-content;
    cursor: pointer;
    :hover {
      .content-wrapper {
        transform: scale(1);
      }
    }
  }
`;

const PriceBadge = styled('div')`
  border-radius: 27px;
  width: fit-content;
  margin-bottom: 8px;
  color: #fff;
  font-family: ${theme.fonts.primary};
  font-size: 11px;
  font-style: normal;
  font-weight: 900;
  line-height: normal;
  &.package-price {
    padding: 5px 15px;
    background-color: ${theme.colors.red};
    color: white;
  }
  &.mastarpris {
    padding: 5px 25px 5px 15px;
    background-color: ${theme.colors.newOrage};
    color: white;
  }
  &.discounted {
    padding: 5px 15px;
    background-color: ${theme.colors.red};
    color: white;
  }
`;

const Tooltip = styled('span')`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 4px;
  right: 4px;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  font-size: 10px;
  background: #ffffff;
  span {
    line-height: 10px;
    font-size: 10px;
    color: ${theme.colors.newOrage};
    font-weight: 600;
  }
  .content-wrapper {
    color: #000;
    transition: 0.3s;
    transform-origin: left bottom;
    transform: scale(0);
    display: flex;
    justify-content: center;
    align-items: center;
    background: #f3f3f3;
    position: absolute;
    bottom: 18px;
    left: 12px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 1;
    width: 300px;
    ${theme.below.md} {
      width: 200px;
    }
  }
  p {
    padding: 20px;
    font-size: 12px;
    line-height: 18px;
  }
`;

const OnCardPriceWrapper = styled('div')`
  .price {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%;
  }
  .new-price {
    color: ${theme.colors.red};
    font-family: ${theme.fonts.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 800;
    line-height: 114%;
  }
  .old-price {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: 114%;
    text-decoration: none;
    opacity: 1 !important;
    text-decoration: line-through;
    margin-top: 2px;
  }
  &.mastarpris {
    .price {
      color: ${theme.colors.newOrage};
      font-family: ${theme.fonts.primary};
      font-size: 13px;
      font-style: normal;
      font-weight: 800;
      line-height: 114%;
    }
  }
  ${theme.below.lg} {
    .price,
    .new-price,
    .old-price {
      font-size: 12px !important;
    }
  }
`;

export const PriceComponent = ({
  product,
  packageProduct,
  selectedVariation,
  onCard,
}) => {
  // Bestäm om produkten är ett paket baserat på product.isPackage
  const isPkg = Boolean(product?.isPackage);

  // Definiera grunden för prisdata (selectedVariation eller product)
  const baseData = selectedVariation || product;

  // Kalla useQuery alltid i samma ordning. Om produkten är ett paket skippar vi queryn.
  const { error, loading, data } = useQuery(PriceHistoryQuery, {
    variables: { articleNumber: product.articleNumber, days: 30 },
    errorPolicy: 'ignore',
    skip: isPkg || !product.articleNumber,
  });

  // För icke-paketprodukter använder vi query-resultatet, annars fallback till tom historik.
  const effectiveData =
    !isPkg && data && data.product && data.product.history
      ? data
      : { product: { history: { previousPrice: [] } } };

  const currentPrice = baseData?.price?.incVat;
  const currentPreviousPrice = baseData?.previousPrice?.incVat;

  const lowestValidPreviousPrice = React.useMemo(() => {
    if (!effectiveData.product.history.previousPrice)
      return currentPreviousPrice;
    const now = new Date();
    const thirtyDaysAgo = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000);
    const historicalPrices = effectiveData.product.history.previousPrice
      .filter((entry) => {
        const priceDate = new Date(entry.timestamp);
        return priceDate >= thirtyDaysAgo && priceDate <= now;
      })
      .map((entry) => entry.price.incVat)
      .filter((price) => price > currentPrice)
      .sort((a, b) => a - b);
    return historicalPrices[0] || currentPreviousPrice;
  }, [effectiveData, currentPrice, currentPreviousPrice]);

  const adjustedPriceData = React.useMemo(() => {
    if (
      !lowestValidPreviousPrice ||
      lowestValidPreviousPrice === currentPreviousPrice
    ) {
      return baseData;
    }
    return {
      ...baseData,
      previousPrice: {
        ...baseData.previousPrice,
        incVat: lowestValidPreviousPrice,
      },
    };
  }, [baseData, lowestValidPreviousPrice, currentPreviousPrice]);

  const discounted =
    lowestValidPreviousPrice &&
    adjustedPriceData?.price?.incVat < lowestValidPreviousPrice;
  const discount = discounted
    ? 100 - (adjustedPriceData?.price?.incVat / lowestValidPreviousPrice) * 100
    : 0;

  // Om onCard är true, rendera en kompakt version
  if (onCard) {
    return (
      <OnCardPriceWrapper
        className={
          product?.customFields?.find((f) => f.key === 'Mastarpris')?.boolValue
            ? 'mastarpris'
            : ''
        }
      >
        <Price
          previousPrice={adjustedPriceData?.previousPrice}
          price={adjustedPriceData?.price}
        />
      </OnCardPriceWrapper>
    );
  }

  // Om produkten är ett paket, returnera dess egna prisdata
  if (isPkg) {
    return (
      <PriceWrapper
        className={
          product?.customFields?.find((f) => f.key === 'Mastarpris')?.boolValue
            ? 'mastarpris'
            : ''
        }
      >
        {isPkg && packageProduct && (
          <PriceBadge className="package-price">PAKETPRIS</PriceBadge>
        )}
        <Price previousPrice={baseData.previousPrice} price={baseData.price} />
      </PriceWrapper>
    );
  }

  // För övriga produkter, använd query-resultatet
  return (
    <PriceWrapper
      className={
        product?.customFields?.find((f) => f.key === 'Mastarpris')?.boolValue
          ? 'mastarpris'
          : ''
      }
    >
      {packageProduct && (
        <PriceBadge className="package-price">PAKETPRIS</PriceBadge>
      )}
      {discounted && (
        <PriceBadge className="discounted">
          {parseInt(discount)}% RABATT
        </PriceBadge>
      )}
      <Price
        previousPrice={adjustedPriceData.previousPrice}
        price={adjustedPriceData.price}
      />
    </PriceWrapper>
  );
};

export default PriceComponent;
