import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';
import { Above } from '@jetshop/ui/Breakpoints';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import { ReactComponent as Up } from '../../svg/CarretUp.svg';
import { ReactComponent as Down } from '../../svg/CarretDown.svg';
import { AccordionContent } from '../ProductPage/AccordionContent';

const TextSectionWrapper = styled('div')`
  max-width: 60rem;
  margin: auto;

  h2 {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    line-height: 160%; /* 48px */
    margin: 0;
    margin-bottom: 30px;
  }

  .columns {
    column-count: ${(props) => props.columns ?? 1};
    column-gap: 40px;

    ${theme.below.lg} {
      column-gap: 20px;
    }
  }

  &.fullwidth {
    max-width: 100%;
    padding: 0 20px;
  }

  ${theme.below.lg} {
    padding: 0 20px;

    h2 {
      color: #000;
      font-family: ${theme.fonts.primary};
      font-size: 25px;
      font-style: normal;
      font-weight: 600;
      line-height: 160%;
      margin-bottom: 10px;
    }

    .accordion-section {
      h3 {
        display: flex;
        align-items: center;
        font-size: 13px;
        margin: 0;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      }

      h3:first-child {
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      h3:not(:last-child) {
        border-bottom: none;
      }

      p {
        font-size: 13px;
      }

      svg {
        height: 13px;
      }
    }
  }
`;

const TextSectionItemWrapper = styled('div')`
  break-inside: avoid;
  page-break-inside: avoid;
  margin-bottom: 30px;

  h3 {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 160%;
    margin: 0;
  }

  p {
    color: #000;
    font-family: ${theme.fonts.primary};
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 160%;
    margin: 0;
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  font-size: 1.5rem;
`;

const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>{isOpen ? <Up /> : <Down />}</IndicatorIcon>
);

export const TextSectionIngressItem = ({ title, text }) => {
  return (
    <TextSectionItemWrapper>
      <h3>{title?.value}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: text?.value,
        }}
      />
    </TextSectionItemWrapper>
  );
};

export const TextSectionItem = ({ title, text }) => {
  return (
    <TextSectionItemWrapper>
      <h3>{title?.value}</h3>
      <div
        dangerouslySetInnerHTML={{
          __html: text?.value,
        }}
      />
    </TextSectionItemWrapper>
  );
};

export const TextSection = ({
  children,
  fullWidth,
  collapseOnMobile,
  columns,
  title,
}) => {
  return (
    <>
      {collapseOnMobile?.value === true ? (
        <Above breakpoint="lg">
          {(matches) =>
            matches ? (
              <TextSectionWrapper
                columns={columns?.value}
                className={fullWidth?.value && 'fullwidth'}
              >
                <h2>{title?.value}</h2>
                <div className="columns">{children}</div>
              </TextSectionWrapper>
            ) : (
              <TextSectionWrapper>
                <h2>{title?.value}</h2>
                {children.map((child, index) => {
                  if (child?.key.includes('Ingress')) {
                    return (
                      <TextSectionIngressItem
                        key={index}
                        title={child?.props?.title}
                        text={child?.props?.text}
                      />
                    );
                  }
                  return null;
                })}
                <Accordion single initialOpenIndexes={[0]}>
                  {({ openIndexes, handleClick }) => (
                    <>
                      {children.map((child, index) => {
                        if (child?.key.includes('Ingress')) {
                          return null;
                        }

                        return (
                          <section className="accordion-section">
                            <h3 onClick={() => handleClick(index)}>
                              <span>{child?.props?.title?.value}</span>
                              <AccordionIndicator
                                isOpen={openIndexes.includes(index)}
                              />
                            </h3>
                            <AccordionContent
                              isOpen={openIndexes.includes(index)}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: child?.props?.text?.value,
                                }}
                              />
                            </AccordionContent>
                          </section>
                        );
                      })}
                    </>
                  )}
                </Accordion>
              </TextSectionWrapper>
            )
          }
        </Above>
      ) : (
        <TextSectionWrapper
          columns={columns?.value}
          className={fullWidth?.value && 'fullwidth'}
        >
          <h2>{title?.value}</h2>
          <div className="columns">{children}</div>
        </TextSectionWrapper>
      )}
    </>
  );
};
